'use client';

import { createContext, ReactNode, useContext } from 'react';

interface EntityProviderProps {
  children: ReactNode;
  entity: Entity;
}

const EntityContext = createContext<Partial<Entity> & { id: string }>({
  id: '',
});

export const useEntity = () => useContext(EntityContext);

export function EntityProvider({ children, entity }: EntityProviderProps) {
  return (
    <EntityContext.Provider value={entity}>{children}</EntityContext.Provider>
  );
}
