'use client';

import { Center, VStack } from '@chakra-ui/react';
import { useParams } from 'next/navigation';

import { Footer } from '@/components/layout/Footer';
import { PageNotFound } from '@/components/PageNotFound';

export default function NotFound() {
  const { entitySlug } = useParams<{ entitySlug: string }>() ?? {};

  return (
    <VStack minH="100vh" justifyContent="space-between">
      <Center minH="62vh" pt="20vh" px="4">
        <PageNotFound title={`Entity "${entitySlug}" not found`} />
      </Center>
      <Footer />
    </VStack>
  );
}
