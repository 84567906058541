'use client';

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Skeleton,
  SystemStyleObject,
  Text,
  TextProps,
} from '@chakra-ui/react';
import Link from 'next/link';

import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { HomeIcon } from '@/components/icons/HomeIcon';
import { useEntity } from '@/contexts/EntityProvider';

type BreadCrumbAttributes = {
  href: string;
  label: string;
  textProps?: TextProps;
};

export type BreadcrumbsConfig = BreadCrumbAttributes | 'loading';

// @todo: do not rely on pathname that much -> add breadcrumb configuration, etc.
interface BreadcrumbsProps {
  config: BreadcrumbsConfig[];
  sx?: SystemStyleObject;
}

export function Breadcrumbs({ config, sx }: BreadcrumbsProps) {
  const entity = useEntity();

  return (
    <Breadcrumb
      separator={<ChevronRightIcon />}
      overflowX="auto"
      mb={{
        base: 4,
        sm: 6,
        md: 8,
      }}
      sx={sx}
    >
      <BreadcrumbItem>
        <Link href={entity?.slug ? `/${entity.slug}` : '/'}>
          <Text>
            <HomeIcon />
          </Text>
        </Link>
      </BreadcrumbItem>
      {config.map((breadcrumb, index) =>
        breadcrumb === 'loading' ? (
          <BreadcrumbItem
            key={`loading-${index}`}
            isCurrentPage={index + 1 === config.length}
          >
            <Skeleton width="60px" height="16px" />
          </BreadcrumbItem>
        ) : (
          <BreadcrumbItem
            key={breadcrumb?.href}
            isCurrentPage={index + 1 === config.length}
          >
            <Link href={breadcrumb?.href}>
              {index + 1 === config.length ? (
                <CurrentBreadcrumb {...breadcrumb} />
              ) : (
                <Text
                  size="sm"
                  color="gray.600"
                  textDecoration="underline"
                  fontWeight="500"
                  display="inline-block"
                  maxW="200px"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  _hover={{
                    color: 'gray.800',
                  }}
                  {...breadcrumb?.textProps}
                >
                  {breadcrumb?.label}
                </Text>
              )}
            </Link>
          </BreadcrumbItem>
        ),
      )}
    </Breadcrumb>
  );
}

function CurrentBreadcrumb({
  ...breadcrumb
}: Pick<BreadCrumbAttributes, 'label' | 'textProps'>) {
  return (
    <Box
      sx={{
        alignItems: 'center',
        background: 'gray.50',
        borderRadius: '6px',
        display: 'flex',

        flexWrap: 'wrap',
        height: '28px',
        justifyContent: 'center',
        padding: '4px 8px',
      }}
    >
      <Text
        size="sm"
        color="gray.700"
        fontWeight="600"
        whiteSpace="nowrap"
        {...breadcrumb.textProps}
      >
        {breadcrumb.label}
      </Text>
    </Box>
  );
}
