import { forwardRef, SVGProps } from 'react';

export const ChevronRightIcon = forwardRef<
  SVGSVGElement,
  SVGProps<SVGSVGElement> & { isExpanded?: boolean }
>(function ChevronRightIcon(props, ref) {
  const { isExpanded, ...svgProps } = props;

  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
      style={{
        transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
        transition: 'transform 0.2s ease-in-out',
      }}
      ref={ref}
    >
      <path
        d="M6.5 12L10.5 8L6.5 4"
        stroke={props.stroke ?? '#D0D5DD'}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});
