'use client';

import Link from 'next/link';

import { Breadcrumbs, BreadcrumbsConfig } from './navigation/Breadcrumbs';

import { useEntity } from '@/contexts/EntityProvider';
import { Button, Heading, Text, VStack } from '@/external-components/chakra';

// @todo: temporary not found page
export function PageNotFound({
  breadcrumbsConfig,
  title = 'Page not found',
}: {
  breadcrumbsConfig?: BreadcrumbsConfig[];
  title?: string;
}) {
  const entity = useEntity();
  const entitySlug = entity?.slug;
  const redirectHomeUrl = entitySlug ? `/${entitySlug}` : '/';
  const btnLabel = entitySlug ? 'Take Me Home' : 'See Municipal Entity List';

  return (
    <>
      {breadcrumbsConfig ? <Breadcrumbs config={breadcrumbsConfig} /> : null}
      <VStack
        sx={{
          alignItems: 'center',
          height: '100%',
          justifyContent: 'center',
          paddingBottom: '4rem',
        }}
      >
        <Heading size="lg">{title}</Heading>
        <Text size="md" textAlign="center">
          Sorry, the page you are looking for doesn&apos;t exist or has been
          moved.
        </Text>
        <Button variant="primary" as={Link} href={redirectHomeUrl}>
          {btnLabel}
        </Button>
      </VStack>
    </>
  );
}
