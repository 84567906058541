'use client';

import { HStack, Text } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';

import { copyToClipBoard } from '@/utils/copyToClipboard';

export function Footer() {
  return (
    <footer
      style={{
        alignItems: 'center',
        display: 'flex',
        flexFlow: 'column nowrap',
        margin: '50px 0',
        padding: '0',
        paddingBottom: '24px',
      }}
    >
      <Image
        src="/images/footer-image.png"
        alt="Footer image"
        width={196}
        height={131}
      />
      <p>© 2024 MuniCollab, Inc.</p>
      <HStack marginTop={2}>
        <Text size="sm" as={Link} href="/privacy-policy" color="gray.600">
          Privacy Policy
        </Text>
        <Text as="span" color="gray.600">
          &#x2022;
        </Text>
        <Text size="sm" as={Link} href="/terms-of-service" color="gray.600">
          Terms of Service
        </Text>
      </HStack>
      <HStack marginTop={1}>
        <Text
          size="sm"
          color="gray.600"
          cursor="pointer"
          onClick={() => {
            if (process.env.NEXT_PUBLIC_COMMIT_SHA) {
              copyToClipBoard(process.env.NEXT_PUBLIC_COMMIT_SHA);
            }
          }}
        >
          Version: {process.env.NEXT_PUBLIC_COMMIT_SHA?.slice(-6)}
        </Text>
      </HStack>
    </footer>
  );
}
